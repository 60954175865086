// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/images/game-bg-dark.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("assets/images/game-bg-light.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("assets/images/free-bet-bg.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "NFont";
  src: url("https://assets.nsoft-cdn.com/public/gravity/icons/nfont.eot");
  src: url("https://assets.nsoft-cdn.com/public/gravity/icons/nfont.eot?#iefix") format("embeded-opentype"), url("https://assets.nsoft-cdn.com/public/gravity/icons/nfont.woff") format("woff"), url("https://assets.nsoft-cdn.com/public/gravity/icons/nfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
.icon {
  font-family: "NFont";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-minimize:before {
  content: "\\e9ca";
}
.icon-files:before {
  content: "\\e902";
}
.icon-betslip:before {
  content: "\\e640";
}
.icon-betslip-a:before {
  content: "\\e668";
}
.icon-arrow-down-a:before {
  content: "\\e676";
}
.icon-arrow-right:before {
  content: "\\e619";
}
.icon-arrow-up-a:before {
  content: "\\e677";
}
.icon-arrow-down-d:before {
  content: "\\e948";
}
.icon-arrow-up-d:before {
  content: "\\e949";
}
.icon-trash:before {
  content: "\\e613";
}
.icon-close:before {
  content: "\\e612";
}
.icon-close-a:before {
  content: "\\e68b";
}
.icon-info:before {
  content: "\\e6a4";
}
.icon-close-a:before {
  content: "\\e68b";
}
.icon-check-a:before {
  content: "\\e63b";
}
.icon-refresh-a:before {
  content: "\\e6b4";
}
.icon-clock:before {
  content: "\\e664";
}
.icon-void:before {
  content: "\\e669";
}
.icon-history:before {
  content: "\\e73a";
}
.icon-history-a:before {
  content: "\\e95c";
}
.icon-calendar:before {
  content: "\\e69d";
}
.icon-ajs:before {
  content: "\\e9b8";
}
.icon-sound-on:before {
  content: "\\e6bc";
}
.icon-sound-off:before {
  content: "\\e6bd";
}
.icon-question:before {
  content: "\\e727";
}
.icon-settings:before {
  content: "\\e643";
}
.icon-plus:before {
  content: "\\e91b";
}
.icon-status-b:before {
  content: "\\e716";
}
.icon-backspace-a:before {
  content: "\\e695";
}
.icon-copy:before {
  content: "\\e994";
}
.icon-arrow-left:before {
  content: "\\e646";
}
.icon-gift:before {
  content: "\\e712";
}
.icon-copy:before {
  content: "\\e994";
}
.icon-question:before {
  content: "\\e95b";
}
.icon-stats:before {
  content: "\\e60e";
}
.icon-results::before {
  content: "\\e95c";
}
.icon-last-tickets::before {
  content: "\\e902";
}
.icon-won:before {
  content: "\\e63b";
}
.icon-open:before {
  content: "\\e664";
}
.icon-lost:before {
  content: "\\e612";
}
.icon-lock:before {
  content: "\\e73d";
}
.n-i-more-sports:before {
  content: "\\e655";
}
.n-i-void:before {
  content: "\\e669";
}
.n-i-arrow-up-double:before {
  content: "\\e93f";
}
.n-i-arrow-up-a:before {
  content: "\\e677";
}
.n-i-arrow-down-a:before {
  content: "\\e676";
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}

/*DEFAULT MODAL CSS */
/* vue 3 handles slots differently than vue 2
** the control of the style has been transfered to parent component
** as it is the one passing the html structure. Therefore the style had to
** be applied here using the :deep() selector
*/
.modal-back {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 101;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--overlay-lock);
  animation-name: toggleModal;
  animation-duration: 0.3s;
  transition: all 0.3s cubic-bezier(0, 0, 1, 1);
  animation-fill-mode: backwards;
  --webkit-backface-visibility: hidden;
  --webkit-transform: translate3d(0, 0, 0);
}
.modal-back .modal-back-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
}
.modal-back .modal {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  background-color: var(--card);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  outline: none;
  position: relative;
}
.modal-back .modal.show-modal {
  animation-name: showModal;
  --webkit-transform: translate3d(0, 0, 0);
  --webkit-backface-visibility: hidden;
  animation-duration: 0.7s;
  transition: all 0.7s cubic-bezier(0.42, 0, 0.03, 0.99);
}
.modal-back .modal.popup-show-modal {
  animation-name: showModal;
  --webkit-transform: translate3d(0, 0, 0);
  --webkit-backface-visibility: hidden;
  animation-duration: 0.7s;
  transition: all 0.7s cubic-bezier(0.42, 0, 0.03, 0.99);
}
.modal-back .modal.show-modal-mobile {
  animation-name: showModalMobile;
  --webkit-transform: translate3d(0, 0, 0);
  --webkit-backface-visibility: hidden;
  animation-duration: 0.3s;
  transition: all 0.3s cubic-bezier(0.0663, 0.4952, 0, 1);
}
.modal-back .modal.modal-close {
  --webkit-transform: translate3d(0, 0, 0);
  --webkit-backface-visibility: hidden;
  animation-name: modalClose;
  animation-duration: 0.5s;
  transition: all 0.7s cubic-bezier(0, 0, 1, 1);
}
.modal-back .modal.popup-modal-close {
  --webkit-transform: translate3d(0, 0, 0);
  --webkit-backface-visibility: hidden;
  animation-name: modalClose;
  animation-duration: 0.5s;
  transition: all 0.7s cubic-bezier(0, 0, 1, 1);
}
.modal-back .modal.modal-close-mobile {
  --webkit-transform: translate3d(0, 0, 0);
  --webkit-backface-visibility: hidden;
  animation-name: modalCloseMobile;
  animation-duration: 0.3s;
  transition: all 0.3s cubic-bezier(1, -0.0048, 1, 1);
}
.modal-back .modal .modal-header {
  padding: 24px 16px 24px 16px;
}
.modal-back .modal .modal-body {
  padding: 8px 16px 0 16px;
  height: 100%;
}
.modal-back .modal .modal-footer {
  display: flex;
  justify-content: flex-end;
}
.modal-back .modal .modal-footer .footer-button {
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  color: var(--secondary);
  height: 40px;
  font-size: 14px;
  width: 80px;
}
@keyframes toggleModal {
0% {
    opacity: 0;
}
100% {
    opacity: 1;
}
}
@keyframes showModal {
0% {
    opacity: 0;
}
30% {
    transform: scale(1);
    opacity: 0;
}
40% {
    transform: scale(1.03);
    opacity: 1;
}
100% {
    transform: scale(1);
}
}
@keyframes showModalMobile {
0% {
    opacity: 0;
    transform: translateY(80px);
}
100% {
    opacity: 1;
    transform: translateY(0px);
}
}
@keyframes modalClose {
0% {
    transform: scale(1);
    opacity: 1;
}
25% {
    transform: scale(1.03);
}
100% {
    transform: scale(1);
    opacity: 0;
}
}
@keyframes modalCloseMobile {
0% {
    opacity: 1;
    transform: translateY(0px);
}
70% {
    opacity: 0;
}
100% {
    opacity: 0;
    transform: translateY(80px);
}
}
:deep(.rebet) {
  display: none;
}
@media (max-width: 660px) {
.modal-back .modal {
    padding: 0;
    max-height: 100% !important;
    height: 100%;
}
.modal .modal-footer {
    position: fixed;
    background-color: var(--card);
    border-top: 2px solid var(--tab-border-color);
    bottom: 0;
    left: 0;
    right: 0;
}
}
/* TICKET CANCEL */
.cancel-ticket .button {
  background: transparent;
  border: 0;
  outline: 0;
  font-size: 14px;
  color: var(--secondary);
  text-transform: uppercase;
  margin-left: 30px;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
}
.cancel-ticket :deep(.modal-footer) {
  float: right;
  padding: 30px;
  position: relative;
}
@media (max-width: 660px) {
.cancel-ticket-wrapper-desktop {
    display: none;
}
.cancel-ticket-wrapper-mobile .cancel-ticket {
    background-color: var(--overlay-lock);
}
.cancel-ticket-wrapper-mobile .cancel-ticket :deep(.modal) {
    background-color: var(--card);
    color: var(--text-primary-1);
    height: 200px !important;
}
.cancel-ticket-wrapper-mobile .cancel-ticket :deep(.modal) .modal-header {
    background-color: var(--card);
    padding: 24px 16px 24px 16px;
}
.cancel-ticket-wrapper-mobile .cancel-ticket :deep(.modal) .modal-body {
    position: relative;
    padding: 0 8px 0 8px;
}
.cancel-ticket-wrapper-mobile .cancel-ticket :deep(.modal) .modal-body .modal-actions {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: 43px;
}
.cancel-ticket-wrapper-mobile .cancel-ticket :deep(.modal) .modal-footer {
    position: relative;
    border: none;
}
}
@media (min-width: 661px) {
.footer-wrapper .footer {
    padding: 0 44px;
}
.footer-wrapper .footer .button {
    padding: 55px 0 27px 0;
}
.cancel-ticket-wrapper-mobile {
    display: none;
}
}
/* QUICK PICK MOBILE MODAL */
.quickpick-mobile-wrapper .modal-back {
  padding: 36px 8px;
}
.quickpick-mobile-wrapper .modal-back .modal {
  overflow: overlay;
  height: auto;
}
.quickpick-mobile-wrapper .modal-back .modal .modal-header {
  padding: 24px 24px 0;
}
.quickpick-mobile-wrapper .modal-back .modal .modal-body {
  height: auto;
  padding-left: 26px;
}
.quickpick-mobile-wrapper .modal-back .modal .modal-footer {
  position: static;
  margin-top: auto;
  padding: 42px 29px 27px 0;
}
* {
  margin: 0;
  padding: 0;
  outline-style: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
html,
body {
  background-color: var(--bg-color-1);
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  -ms-overflow-style: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  overflow: auto;
  height: 100vh;
  -webkit-text-size-adjust: 100%; /* Prevent font scaling */
  -moz-text-size-adjust: 100%;
       text-size-adjust: 100%;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
#app {
  font-family: "Roboto", sans-serif;
}
.app {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: auto;
  color: var(--text-primary-1);
  font-size: var(--font-size-medium, 14px);
}
.app.dark {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.app.light {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
.app .cancel-ticket-confirm .modal,
.app .free-bet-modal .modal {
  background-color: var(--card);
  height: 182px;
}
.app .cancel-ticket-confirm .modal .modal-header,
.app .cancel-ticket-confirm .modal .modal-body,
.app .free-bet-modal .modal .modal-header,
.app .free-bet-modal .modal .modal-body {
  color: var(--text-primary-1);
}
.app .cancel-ticket-confirm .modal .modal-header,
.app .free-bet-modal .modal .modal-header {
  font-size: 16px;
}
.app .cancel-ticket-confirm .modal .modal-body,
.app .free-bet-modal .modal .modal-body {
  font-size: 14px;
}
.app .cancel-ticket-confirm .modal .modal-body .modal-actions,
.app .free-bet-modal .modal .modal-body .modal-actions {
  display: none;
}
.app .cancel-ticket-confirm .modal .modal-footer,
.app .free-bet-modal .modal .modal-footer {
  padding-right: 43px;
  position: relative;
}
.app .cancel-ticket-confirm .modal .button,
.app .free-bet-modal .modal .button {
  background: transparent;
  border: 0;
  outline: 0;
  font-size: 14px;
  color: var(--secondary);
  text-transform: uppercase;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  padding: 31px 0 27px 0;
}
.free-bet-modal .modal {
  max-height: 348px !important;
}
.free-bet-modal .modal .modal-header {
  display: none;
}
.free-bet-modal .modal .modal-body {
  height: 348px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
}
.free-bet-modal .modal .modal-body img {
  position: absolute;
  top: -2%;
  left: 0;
  max-width: 100%;
}
.free-bet-modal .modal .modal-body .body-message-box {
  position: absolute;
  width: 100%;
  top: 66%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.free-bet-modal .modal .modal-body .body-message-box .title {
  font-size: 42px;
  font-weight: 700;
  color: #90b653;
  margin-bottom: 25px;
  text-transform: uppercase;
}
.free-bet-modal .modal .modal-body .body-message-box .subtitle {
  font-size: 18px;
  font-weight: 700;
}
.free-bet-modal .modal .modal-body .body-message-box .subtitle-2 {
  font-size: 14px;
  color: var(--text-primary-3);
}
.free-bet-modal .modal .modal-footer {
  display: none;
}
.free-bet-modal.desktop .modal .modal-body {
  background-image: none;
}
.free-bet-modal.desktop .modal .modal-body img {
  height: 60%;
  left: 50%;
  top: -2%;
  transform: translate(-50%, 0);
}
.free-bet-modal.desktop .modal .modal-body .body-message-box {
  top: 60%;
}
.free-bet-modal.desktop .modal .modal-body .body-message-box .title {
  margin-bottom: 10px;
}
.free-bet-modal.desktop .modal .modal-body .body-message-box .pre-title {
  display: none;
}
.free-bet-modal.desktop .modal .modal-body .action {
  position: absolute;
  bottom: 0;
  right: 16px;
}
.free-bet-modal.mobile .modal {
  height: 70% !important;
}
.free-bet-modal.mobile .modal .modal-body {
  height: 100% !important;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
.free-bet-modal.mobile .modal .modal-body .action {
  position: absolute;
  left: 0;
  bottom: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.free-bet-modal.mobile .modal .modal-body .action .button {
  min-width: 150px;
  background: #90b653;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  padding: 0;
  color: var(--text-secondary-1);
  font-weight: 500;
}
@media (max-width: 660px) {
.app .cancel-ticket-confirm {
    background-color: var(--overlay-lock);
}
.app .cancel-ticket-confirm .modal {
    background-color: var(--card);
    color: var(--text-primary-1);
    height: 182px !important;
}
.app .cancel-ticket-confirm .modal .modal-header {
    background-color: var(--card);
    padding: 24px 24px 30px 24px;
}
.app .cancel-ticket-confirm .modal .modal-body {
    position: relative;
    padding: 0 24px;
}
.app .cancel-ticket-confirm .modal .modal-body .modal-actions {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: 43px;
}
.app .cancel-ticket-confirm .modal .modal-footer {
    display: none;
}
}
@media (-webkit-device-pixel-ratio: 1.1) {
.ticket-wrapper {
    zoom: 0.9;
}
}
@media (-webkit-device-pixel-ratio: 1.25) {
.ticket-wrapper {
    zoom: 0.8;
}
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import mutationTypes from './mutationTypes';
import { filter, merge, shuffle, sampleSize, range, omit, each, map, isEmpty, isArray, findIndex } from 'lodash';
import auth from '@nsftx/games-sdk-js/src/api/auth';
import results from '@nsftx/games-sdk-js/src/api/results';
import statistics from '@nsftx/games-sdk-js/src/api/statistics';
import eventBus from '@nsftx/games-sdk-js/src/utility/eventBus';
import { ajsAPI, ticketAPI } from '@/api';

export default {
  async handleTerminalMessages({ dispatch }, event) {
    const { data } = event.data;
    const { action } = event.data;
    switch (action) {
      case 'Slave.Load': {
        dispatch('setIsTerminal', true);
        dispatch('user/setTerminalUser', true);
        const { device, betshop, company, account, terminal, user, scrollButtons, betslip } = data;
        dispatch('setDeviceData', device);
        dispatch('setBetshopData', betshop);
        dispatch('setCompanyData', company);
        dispatch('setAccountData', account);
        dispatch('setUserBalance', terminal.balance);
        dispatch('setArrowsVisibility', scrollButtons);
        dispatch('setTerminalUserData', user);
        if (betslip.blockers.length) {
          dispatch('bettingDisable', true);
          dispatch('gamesBetslip/setBetslipBlockers', {
            blockers: betslip.blockers,
            type: 'add',
          });
        }
        break;
      }
      case 'UI.Show': {
        setTimeout(() => {
          dispatch('setActiveComponent', data.name[0]);
        }, 200);
        break;
      }
      case 'Slave.Snooze': {
        dispatch('setActiveComponent', null);
        break;
      }
      case 'Tickets.Checked': {
        dispatch('clearTicketCheckData');
        dispatch('setIsLoaded', false);

        const ticketPin = data.ticket.ticketPin ?? null;

        ticketAPI
          .checkTicketByBarcode(data.ticket.id.toUpperCase())
          .then((response) => {
            const formatted = response.data;
            formatted.ticketPin = ticketPin;
            dispatch('setTicketDetailsData', formatted);
            dispatch('setIsLoaded', true);
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
          });
        break;
      }
      case 'Tickets.FetchHistory': {
        ticketAPI
          .getTicketHistory(data)
          .then((response) => {
            dispatch('setTicketHistoryTerminal', response.data.message);
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
          });
        break;
      }
      case 'Betslip.Blocked': {
        dispatch('bettingDisable', true);
        dispatch('gamesBetslip/setBetslipBlockers', {
          blockers: data.blockers,
          type: 'add',
        });
        break;
      }
      case 'Betslip.Unblocked': {
        dispatch('bettingDisable', false);
        dispatch('gamesBetslip/setBetslipBlockers', {
          blockers: data.blockers,
          type: 'remove',
        });
        break;
      }
    }
  },
  timerTick({ getters, dispatch }) {
    if (getters.nextRoundTime > 0) {
      let minutes = Math.floor(getters.nextRoundTime / 60);
      let seconds = getters.nextRoundTime % 60;
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      seconds = seconds < 10 ? `0${seconds}` : seconds;
      const timer = `${minutes}:${seconds}`;
      dispatch('setNextRoundTime', getters.nextRoundTime - 1);
      dispatch('setTimer', timer);
    }
  },
  setTimer({ commit }, payload) {
    commit(mutationTypes.SET_TIMER, payload);
  },
  setRoundInProgress({ commit }, payload) {
    commit(mutationTypes.SET_ROUND_IN_PROGRESS, payload);
  },
  setCurrentEventId({ commit }, payload) {
    commit(mutationTypes.SET_CURRENT_EVENT_ID, payload);
  },
  ticketDetailsRecheck({ getters, dispatch }, status) {
    const { ticketDetailsData } = getters;
    if (ticketDetailsData) {
      if (ticketDetailsData.status.id === status) {
        ticketAPI
          .checkTicketByBarcode(ticketDetailsData.id)
          .then((response) => {
            const ticketPin = ticketDetailsData.ticketPin;
            const formatted = response.data;
            formatted.ticketPin = ticketPin;
            dispatch('setTicketDetailsData', formatted);
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
          });
      }
    }
  },
  setTicketToRebet({ commit }, payload) {
    commit(mutationTypes.SET_TICKET_TO_REBET, payload);
  },
  setNextRoundTime({ commit }, payload) {
    commit(mutationTypes.SET_NEXT_ROUND_TIME, payload);
  },
  setArrowsVisibility({ commit }, payload) {
    commit(mutationTypes.SET_ARROWS_VISIBILITY, payload);
  },
  setIsLoaded({ commit }, payload) {
    commit(mutationTypes.SET_IS_LOADED, payload);
  },
  clearTicketCheckData({ commit }) {
    commit(mutationTypes.SET_TICKET_DETAILS_DATA, null);
  },
  setActiveComponent({ commit }, payload) {
    commit(mutationTypes.SET_ACTIVE_COMPONENT, payload);
  },
  setTicketDetailsData({ commit }, payload) {
    commit(mutationTypes.SET_TICKET_DETAILS_DATA, payload);
  },
  clearTicketDetailsData({ commit }) {
    commit(mutationTypes.SET_TICKET_DETAILS_DATA, {});
  },
  setTicketHistoryTerminal({ commit }, payload) {
    commit(mutationTypes.SET_TICKET_HISTORY_TERMINAL, payload);
  },
  setTerminalUserData({ commit }, payload) {
    commit(mutationTypes.SET_TERMINAL_USER_DATA, payload);
  },
  setDeviceData({ commit }, payload) {
    commit(mutationTypes.SET_DEVICE_DATA, payload);
  },
  setBetshopData({ commit }, payload) {
    commit(mutationTypes.SET_BETSHOP_DATA, payload);
  },
  setCompanyData({ commit }, payload) {
    commit(mutationTypes.SET_COMPANY_DATA, payload);
  },
  setAccountData({ commit }, payload) {
    commit(mutationTypes.SET_ACCOUNT_DATA, payload);
  },
  setIsTerminal({ commit }, payload) {
    commit(mutationTypes.SET_IS_TERMINAL, payload);
  },
  setConfig({ commit }, config) {
    commit(mutationTypes.SET_CONFIG, config);
  },
  setGameState({ commit }, gameState) {
    commit(mutationTypes.SET_GAME_STATE, gameState);
  },
  updateGameState({ commit }, gameState) {
    commit(mutationTypes.UPDATE_GAME_STATE, gameState);
  },
  setBetLimits({ commit }, limit) {
    commit(mutationTypes.SET_BET_LIMITS, limit);
  },
  setBets({ commit }, bets) {
    const betsArr = Object.values(bets);
    const normalBets = betsArr.filter((bet) => bet.betType === 'Normal');
    const specialBets = betsArr.filter((bet) => bet.betType === 'Special');
    commit(mutationTypes.SET_NORMAL_BETS, normalBets);
    commit(mutationTypes.SET_SPECIAL_BETS, specialBets);
  },
  toggleBetNumbers({ getters, commit, dispatch }, betNumbers) {
    if (isEmpty(betNumbers)) {
      dispatch('clearSelection');
      return;
    }
    if (betNumbers.length < 10) dispatch('resetSelectedColor');
    commit('TOGGLE_BET_NUMBERS', betNumbers);
    dispatch('resetSpecials');
    dispatch('resetFirstBallColor');

    if (betNumbers.length > 1) {
      dispatch('resetPreballBet');
    } else if (betNumbers.length === 1) {
      if (getters.bettingData.preballBet.active) {
        dispatch('togglePreballBet', true);
      }
    }
    if (betNumbers.length === 10) dispatch('checkNumbersColor', betNumbers);
  },
  checkNumbersColor({ state, commit }, betNumbers) {
    const [...numbers] = betNumbers;
    const firstNumber = numbers[0];
    let sameColor = numbers.slice(1).every((num) => num.value % 5 === firstNumber.value % 5);
    const colorKey = findIndex(state.bettingRules.bingoColors, (color) => color === firstNumber.hex);
    const selectedColor = {
      checked: true,
      color: firstNumber.hex,
    };
    if (sameColor && !state.bettingData.selectedBet.combinations) {
      state.bettingData.selectedBet.color = firstNumber.hex;
      state.bettingData.selectedBet.market = state.translations.translations[`luckyx_color_${colorKey}`];
      commit(mutationTypes.SET_SELECTED_COLOR, selectedColor);

      eventBus.$emit('resetSystemSelection');
    }
  },
  toggleColors({ commit }, colorGroup) {
    commit(mutationTypes.SET_SELECTED_COLOR, colorGroup);
    commit(mutationTypes.TOGGLE_COLORS, colorGroup);
  },
  toggleBetNumbersByColor({ dispatch, getters }, colorGroup) {
    let selectedBetNumbersByColor = [];
    if (colorGroup.checked) {
      selectedBetNumbersByColor = filter(getters.bingoNumbers, (number) => number.hex === colorGroup.color);
    }
    dispatch('toggleBetNumbers', selectedBetNumbersByColor);
    dispatch('toggleColors', colorGroup);
  },
  togglePreballBet({ dispatch, commit }, active) {
    commit(mutationTypes.TOGGLE_PREBALL_BET, active);
    dispatch('resetSpecials');
    dispatch('resetSelectedColor');
    dispatch('resetFirstBallColor');
    if (!active) dispatch('resetSelectedBetNumbers');
  },
  toggleFirstBallColor({ dispatch, commit }, { bet, outcome }) {
    dispatch('resetSpecials');
    dispatch('resetSelectedBetNumbers');
    dispatch('resetSelectedColor');
    dispatch('resetPreballBet');
    if (!outcome) {
      commit(mutationTypes.CLEAR_SELECTED_BET);
    } else {
      commit(mutationTypes.TOGGLE_FIRST_BALL_COLOR, { bet, outcome });
    }
  },
  toggleSpecialBets({ dispatch, commit }, { specialBet, outcomeIndex }) {
    commit(mutationTypes.TOGGLE_SPECIAL_BETS, { specialBet, outcomeIndex });
    dispatch('resetSelectedBetNumbers');
    dispatch('resetSelectedColor');
    dispatch('resetFirstBallColor');
    dispatch('resetPreballBet');
  },
  quickPick({ state, dispatch, getters }, pick) {
    const rules = state.bettingRules;
    const numbersRange = range(rules.minBingoNumber, rules.maxBingoNumber + 1);
    const numbersShuffle = shuffle(numbersRange);
    const numbersSample = sampleSize(numbersShuffle, pick.label);
    const filteredNumbers = filter(getters.bingoNumbers, (number) => numbersSample.includes(number.value));
    dispatch('toggleBetNumbers', filteredNumbers);
  },
  system({ state, commit, dispatch }, payload) {
    commit(mutationTypes.SET_SYSTEM_NUMBER, payload.value);
    dispatch('toggleBetNumbers', state.bettingData.selectedBetNumbers);
  },
  resetSpecials({ commit }) {
    commit(mutationTypes.RESET_SPECIAL);
  },
  resetFirstBallColor({ commit }) {
    commit(mutationTypes.RESET_FIRST_BALL_COLOR);
  },
  resetPreballBet({ commit }) {
    commit(mutationTypes.RESET_PREBALL_BET);
  },
  resetSelectedColor({ commit }) {
    commit(mutationTypes.RESET_SELECTED_COLOR);
  },
  resetSelectedBetNumbers({ commit }) {
    commit(mutationTypes.RESET_SELECTED_BET_NUMBERS);
  },
  setIsBetslipOpen({ commit }, isBetslipOpen) {
    commit(mutationTypes.SET_IS_BETSLIP_OPEN, isBetslipOpen);
  },
  async setJackpotState({ commit, getters }) {
    const jackpot = await ajsAPI.getJackpot(getters.config);

    if (jackpot.jackpotId) {
      commit(mutationTypes.SET_JACKPOT_STATE, jackpot);
    }
  },
  getResults({ getters, dispatch }) {
    results.getResults(getters.config).then((resultsResponse) => {
      dispatch('setResults', resultsResponse.data);
    });
  },
  getStats({ dispatch, getters }) {
    statistics.getStatistics(getters.config).then((stats) => {
      dispatch('setStats', stats.data);
    });
  },
  setResults({ commit }, data) {
    commit(mutationTypes.SET_PRODUCT_RESULTS, data);
  },
  setStats({ commit }, data) {
    commit(mutationTypes.SET_PRODUCT_STATS, data);
  },
  bettingDisable({ commit }, disable) {
    commit(mutationTypes.BETTING_DISABLE, disable);
  },
  resetSystemNum({ commit }) {
    commit(mutationTypes.RESET_SYSTEM_NUM);
  },
  clearSelection({ dispatch, commit }) {
    dispatch('resetSelectedBetNumbers');
    dispatch('resetPreballBet');
    dispatch('resetSelectedColor');
    dispatch('resetSpecials');
    dispatch('resetFirstBallColor');
    dispatch('resetSystemNum');
    commit(mutationTypes.CLEAR_SELECTED_BET);
  },
  setUserData({ dispatch }, payload) {
    let user = {};
    if (payload.auth) {
      user = {
        auth: omit(payload.auth, 'user'),
        profile: payload.auth.user || payload.user,
      };
    } else {
      user = {
        auth: {
          token: payload.token,
          tpToken: payload.tpToken,
        },
        profile: omit(payload, ['token', 'tokenType']),
      };
    }
    dispatch('user/updateUser', user, { root: true });
  },
  setUserBalance({ dispatch, getters }, payload) {
    const divider = getters.config.ui.config.balanceDivider;
    const balance = divider ? payload / divider : payload;
    dispatch('user/setUserBalance', balance, { root: true });
  },
  updateRound({ commit }, round) {
    commit(mutationTypes.UPDATE_ROUND, round);
  },
  formatPlayerTickets({ getters }, payload) {
    const resolveOutcome = (bet) => {
      if (bet.type === 22) {
        const colors = [];
        let colorsString = '';
        let colorIndex = '';
        each([0, 1, 2, 3, 4, 5], (n) => {
          if (parseInt(bet.value, 10) & (2 ** n)) {
            colorIndex = n === 0 ? 4 : n - 1;
            colors.push(getters.firstBallColor.outcomes[colorIndex]);
            colorsString = colors.join(' ');
          }
        });
        return colorsString;
      }
      if (bet.type > 20 && bet.type !== 22 && bet.type !== 23) {
        return getters.getSpecialBet(bet.type).outcomes[bet.value];
      }
      return isArray(bet.value) ? bet.value.join(',') : bet.value;
    };
    return map(payload, (tickets) => {
      let bets = [];
      bets = map(tickets.bets, (bet) => ({
        id: bet.id,
        status: bet.status,
        round: bet.eventId,
        market: bet.bet.title,
        outcome: resolveOutcome(bet),
        outcomeIndex: bet.type >= 21 ? bet.value : null,
        stake: bet.amount,
        odd: bet.odd,
        eventValue: bet.type < 21 || bet.type === 23 ? bet.outcome.drawn : [],
        winnings: bet.winnings,
        type: bet.type,
        tax: bet.tax,
        system: bet.system,
      }));
      return {
        id: tickets.id,
        payout: tickets.payout,
        payin: tickets.payin,
        payinTax: tickets.payinTax,
        payoutTax: tickets.payoutTax,
        superBonus: tickets.superBonus,
        createdAt: tickets.createdAt,
        status: tickets.status,
        maxPossibleWin: tickets.maxPossibleWin,
        bonuses: tickets.bonuses,
        isFreeBet: tickets.isFreeBet,
        bets,
      };
    });
  },
  toggleModalInfo({ commit }) {
    commit(mutationTypes.TOGGLE_MODAL_INFO);
  },
  setIsBettingDisabled({ commit }, payload) {
    commit(mutationTypes.SET_IS_BETTING_DISABLED, payload);
  },
  removeUser({ commit }) {
    commit(mutationTypes.RESET_USER);
  },
  setLayout({ commit }, payload) {
    commit(mutationTypes.SET_LAYOUT, payload);
  },
  async getPlayer({ getters, dispatch }, { config, token }) {
    try {
      const player = await auth.authenticate(config, token);
      const isLoggedIn = !!player.uuid;
      const userData = {
        auth: {
          token: player.auth.token,
          tpToken: player.auth.thirdPartyToken || null,
        },
        profile: merge({
          balance: player.balance,
          balanceTotal: player.balance,
          logged: isLoggedIn,
          id: player.uuid,
        }),
      };
      if (isLoggedIn) {
        dispatch('user/updateUser', userData);
        dispatch('setUserBalance', player.balance);
      }
    } catch (error) {
      const message = {
        message: getters.translations.general_player_login,
        notificationTimeout: 7,
      };
      dispatch('notifications/setNotification', message);
    }
  },
  setQuickpickCurrentValue({ commit }, value) {
    commit(mutationTypes.SET_QUICKPICK_CURRENT_VALUE, value);
  },
  toggleFreeBetModal({ commit }, show) {
    commit(mutationTypes.TOGGLE_FREE_BET_MODAL, show);
  },
  updateConfig({ commit }, property) {
    commit(mutationTypes.UPDATE_CONFIG, property);
  },
  setNextGenConfig({ commit }, config) {
    commit(mutationTypes.SET_NEXT_GEN_CONFIG, config);
  },
  updateConfigWithNextGenConfig({ state, commit }) {
    const { nextGenConfig } = state;
    if (!nextGenConfig) return;
    Object.entries(nextGenConfig).forEach(([key, value]) => {
      commit(mutationTypes.UPDATE_CONFIG, { [key]: value });
    });
  },
};
